import React from 'react'
import Navbar from './Nav'

export const EmbededEmailCapture = () => {
    return (
        <div>
            <Navbar />
            <iframe title="email-form" className='full-iframe' src="https://omniform1.com/signup/v1/61f45290e46202ad70137da1_61f45398e847e3a0a71c2391.html" />
        </div>
    )
}