import React from 'react';
import ReactDOM from 'react-dom';
import './Styles/dist/styles.css';
import { BrowserRouter, Route} from "react-router-dom";
import Home from './Components/Home'
import Works from './Components/Works'
import Contact from './Components/Contact'
import {EmbededEmailCapture} from './Components/EmbededEmailCapture'


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Route path="/" exact component={Home} />
      <Route path="/works" exact component={Works} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/email-list" exact component={EmbededEmailCapture} />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);


