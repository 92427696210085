
function Navbar() {
  return (
    <div className="nav-container">
          <a href="/">home</a>
          <a href="/works">works</a>
          <a href="/contact">contact</a>
          <a href="/email-list">email list</a>
    </div>
  );
}

export default Navbar;
