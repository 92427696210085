import logo from '../media/logo2_no_bg.png';
import Navbar from './Nav'

function Contact() {
  return (
    <div className="page">
        <Navbar />
            <a href="/">
                <img src={logo} alt="SJM logo" className="logo-small" />
            </a>
      <div className="fixed-centered-container child-offset">
        <div className="contact-info">
            <p>stephyjmooremedia@gmail.com</p>
            <p>651.315.6350</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
