import logo from '../media/logo2_no_bg.png';
import Navbar from './Nav';
import React from 'react'
let worksList = require('../contentData/worksList.json')

class Works extends React.Component {
    constructor(props){
        super(props)

        this.state = {selectedClient: null}

        this.parseClientList = this.parseClientList.bind(this)
        this.parseWorksList = this.parseWorksList.bind(this)
        this.setCurrentClient = this.setCurrentClient.bind(this)
    }
    parseClientList = () => {
        return(
            worksList.map((item) => {
                const client = item.client;
                const id = client.name.split(' ').join('')
                return(
                    <div className="works-list" key={id}>
                        <div className="client-name" onClick={this.setCurrentClient}>
                            <h2>{client.name}</h2>
                        </div>
                        {this.state.selectedClient === client.name ? this.parseWorksList(client.works) : null}
                    </div>
                )
            })
        )
    }

    parseWorksList = (client) => {
        return(
            client.map((item) => {
                return(
                    // turn this into its own component
                    <div key={item.name}>
                        <div className="work-item-name">
                            <h3>{item.name}</h3>
                        </div>
                        <iframe width="560" height="315" src={item.embedLink} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title={item.name}></iframe>
                    </div>
                )
            })
        )
    }

    setCurrentClient = (e) => {
        e.preventDefault()
        if (this.state.selectedClient !== e.target.innerText){
            this.setState({selectedClient: e.target.innerText})
        } else {
            this.setState({selectedClient: null})
        }
    }

    render(){
        return (
            <div className="page">
                <Navbar />
                <a href="/">
                    <img src={logo} alt="SJM logo" className="logo-small" />
                </a>
                <div className="fixed-centered-container child-offset" >
                {this.parseClientList()}
            </div>
            </div>
        );
    }
}

export default Works;
