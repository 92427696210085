import logo from '../media/logo2_no_bg.png';
// import '../Styles/dist/styles.css';

function Home() {
  return (
    <div className="page">
        <div className="fixed-centered-container top-offset">
            <img src={logo} alt="SJM logo" className="logo-big" />
            <div className="nav-center">
                <a href="/works">works</a>
                <a href="/contact">contact</a>
                <a href="/email-list">email list</a>
            </div>
        <div className="reel">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/oWMGd6TRyYw" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="2020 Director Reel"></iframe>
        </div>
      </div>
      
    </div>
  );
}

export default Home;
